import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/main.scss';
import { Helmet } from "react-helmet"
import CookieConsent from "react-cookie-consent";


class Layout extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Finteq Services - Driving your telecommunications business</title>
          <meta description="Finteq Services - Driving your telecommunications business"/>
          <meta author="Finteq Services"/>
        </Helmet>
        <Navbar/>
          {this.props.children}
        <Footer/>
          <CookieConsent
    				buttonStyle={{background: "#00A659", color:"white"}}
    			>
    			    This website uses cookies to enhance the user experience.
    					In clicking "I understand", you are accepting the
              <a href="/privacy-policy"> terms of service</a> and
              <a href="/cookie-policy"> cookie policy</a> of this website
    			</CookieConsent>
      </>
    )
  }
}

export default Layout;
