import React from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import logo from '../images/logo.jpg';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col xs={6}>
              <img src={logo} className="img-fluid footer-logo"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}><hr/></Col>
            <Col xs={12} md={3}>
              <a className="footer-link footer-link-main" href="/">Home</a>
            </Col>
            <Col xs={12} md={3}>
              <a className="footer-link footer-link-main" href="/#about">About</a>
            </Col>
            <Col xs={12} md={3}>
              <a className="footer-link footer-link-main" href="/#services">Services</a>
            </Col>
            <Col xs={12} md={3}>
              <a className="footer-link footer-link-main" href="/#contact">Contact Us</a>
            </Col>
            <Col xs={12}>
              <div className="footer-legal text-right">
                <span className="copyright text-left">© 2019 Finteq Serices LTD. All rights reserved.</span>
                <a className="footer-link" href="/privacy-policy">Privacy Policy</a>
                <a className="footer-link" href="/cookie-policy">Cookie Policy</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer;
